<template>
  <b-row>
    <b-col class="col-12 mt-0 mb-0">
      <b-alert variant="warning" show>
        <b
          >Please be very careful on what you do on this page if you are on the
          live version</b
        >, as it will have an immediate impact on the live version of the
        portal.
      </b-alert>
    </b-col>
    <b-col class="mb-32 mt-12 col-12">
      <div
        class="hp-bg-black-bg py-32 py-sm-64 px-24 px-sm-48 px-md-80 position-relative overflow-hidden hp-page-content"
        style="border-radius: 32px"
      >
        <svg
          width="358"
          height="336"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="position-absolute hp-rtl-scale-x-n1"
          style="bottom: 0px; right: 0px"
        >
          <path
            d="M730.404 135.471 369.675-6.641l88.802 164.001-243.179-98.8 246.364 263.281-329.128-126.619 114.698 166.726-241.68-62.446"
            stroke="url(#a)"
            stroke-width="40"
            stroke-linejoin="bevel"
          ></path>
          <defs>
            <linearGradient
              id="a"
              x1="315.467"
              y1="6.875"
              x2="397.957"
              y2="337.724"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#fff"></stop>
              <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
        <b-row>
          <div class="col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <h1 class="mb-0 hp-text-color-black-0">
                  Undisputed Noobs Portal
                </h1>
              </div>
              <div class="col-12">
                <p class="h5 mb-0 mt-24 hp-text-color-black-0">
                  Current Version: {{ this.$store.state.themeConfig.version }}
                </p>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </b-col>
    <b-col class="mb-32 col-12">
      <b-card-group style="min-height: 370px" deck>
        <b-card title="Portal Control" class="border-0">
          <b-form-group
            label="Maintenance Mode"
            label-for="maintenance-mode"
            class="form-control"
          >
            <b-form-checkbox
              id="maintenance-mode"
              v-model="maintenanceMode"
              switch
              @change="toggleMaintenanceMode"
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Allow Old Application Migration"
            label-for="allow-migration"
            class="form-control"
          >
            <b-form-checkbox
              id="allow-migration"
              v-model="allowOldApplicationMigration"
              switch
              disabled
            ></b-form-checkbox>
          </b-form-group>
        </b-card>
        <b-card title="Data Control" class="border-0">
          <b-tabs pills content-class="mt-3">
            <b-tab title="Genopets">
              <b-form-group
                label="Enable automatic Genopets data update (every hour)"
                label-for="allow-genopets-update"
                class="form-control"
              >
                <b-form-checkbox
                  id="allow-genopets-update"
                  v-model="allowGenopetsUpdate"
                  switch
                  :disabled="disableAll"
                ></b-form-checkbox>
              </b-form-group>
              <b-form-group
                label="Enable automatic issue notification on Discord"
                label-for="allow-genopets-issue-notification"
                class="form-control"
              >
                <b-form-checkbox
                  id="allow-genopets-issue-notification"
                  v-model="allowGenopetsIssueNotification"
                  switch
                  :disabled="disableAll"
                ></b-form-checkbox>
              </b-form-group>
            </b-tab>
            <b-tab title="Million on Mars">
              <h4 class="text-center">Coming Soon</h4>
            </b-tab>
          </b-tabs>
          <p class="text-black-60">
            These data are mostly retrieved by an external API, these changes
            can take several minutes to take place.
          </p>
        </b-card>
      </b-card-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BAlert,
  BCard,
  BCardGroup,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BTab,
  BTabs,
} from "bootstrap-vue";
import adminServices from "../../../../services/admin.services";

export default {
  name: "PortalSettings",
  components: {
    BCol,
    BRow,
    BAlert,
    BCard,
    BCardGroup,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BTab,
    BTabs,
  },
  data() {
    return {
      showAlert: false,
      maintenanceMode: false,
      allowOldApplicationMigration: false,
      allowGenopetsUpdate: false,
      allowGenopetsIssueNotification: false,
      disableAll: true,
    };
  },
  async mounted() {
    adminServices.getSettings().then((res) => {
      var data = res.data.Items;

      for (var i in data) {
        var settingName = data[i].name;
        var settingStatus = data[i].status;

        this[settingName] = settingStatus;
      }
    });
  },
  methods: {
    async toggleMaintenanceMode() {
      adminServices
        .setMaintenanceMode(this.maintenanceMode)
        .then((res) => {
          if (!res.success) {
            this.$bvToast.toast(res.message, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Portal Maintenance mode set", {
              title: "Success",
              solid: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>
